import React from "react";
import LoginForm from "../components/LoginForm";
import Footer from "../components/Footer";

const LogIn = () => {
  return (
    <>
      <LoginForm />
      <Footer />
    </>
  );
};

export default LogIn;
