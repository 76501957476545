import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { Selectuser } from "../../state/authSlice";
import TableComponents from "../tableComponent/TableComponents";
import "./MyShipments.css";

const MyShipments = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = useSelector(Selectuser);
  const id = auth._id;

  useEffect(() => {
    const getShipment = async () => {
      try {
        setLoading(true);

        const shipmentResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/shipment/viewShipment/${id}`
        );

        const shipment = shipmentResponse.data;
        setDatas(shipment);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    getShipment();
  }, [id]);

  const data = useMemo(() => datas, [datas]);

  const columns = [
    {
      header: "Tracking Number",
      accessorKey: "trackingnumber",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    {
      header: "Origin Country",
      accessorKey: "origincountry",
    },

    {
      header: "Origin City",
      accessorKey: "origincity",
    },

    {
      header: "Sender Name",
      accessorKey: "senderfullname",
    },
    {
      header: "Sender Address",
      accessorKey: "senderaddress",
    },

    {
      header: "Sender Phone Number",
      accessorKey: "senderphonenumber",
    },
    {
      header: "Destination Country",
      accessorKey: "destinationcountry",
    },

    {
      header: "Destination City",
      accessorKey: "destinationcity",
    },

    {
      header: "Reciver Full Name",
      accessorKey: "receiverfullname",
    },
    {
      header: "Reciver Address",
      accessorKey: "receiveraddress",
    },

    {
      header: "Reciver Phone Number",
      accessorKey: "receiverphonenumber",
    },

    {
      header: "Shipment Weight in Kg",
      accessorKey: "shipmentweight",
    },
    {
      header: "Shipment Length",
      accessorKey: "shipmentlength",
    },
    {
      header: "Shipment Width",
      accessorKey: "shipmentwidth",
    },
    {
      header: "Shipment Height",
      accessorKey: "shipmentheight",
    },
    {
      header: "Shipment Created",
      accessorKey: "created_at",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
  ];

  console.log(datas.length !== 0 + "resulr");

  return (
    <div className='myshipments-main-div'>
      {datas.length !== 0 ? (
        <>
          <div className='myshipments-top-heading-div'>
            <p>Total Shipments:</p>
            <h3 className='myshipments-heading-total'>{datas.length ?? 0}</h3>
          </div>
          {loading ? (
            <div className='loading-spin'>
              <Spin size='large' />
            </div>
          ) : (
            <TableComponents
              columns={columns}
              data={data}
              tableClassName={"table-container-shipment "}
            />
          )}
        </>
      ) : (
        <div>
          <h1>No shipments available</h1>
        </div>
      )}
    </div>
  );
};

export default MyShipments;
