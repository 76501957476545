import React from "react";
import "../components/Success.css";

const Success = () => {
  return (
    <div className='success-main'>
      <div className='success-modal-content'>
        <h1 className='success-modal-content-heading'>Payment Completed</h1>
        <div>
          <p className='success-tracking-number-text'>
            You can view your shipment in the shipment history
          </p>
          <p className='success-tracking-number-warning'>
            You can use our tracking system to track your shipment at anytime
            using your Tracking Number
          </p>
        </div>
      </div>
    </div>
  );
};

export default Success;
