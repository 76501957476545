import React from "react";
import MyShipments from "../components/shipmentHistory/MyShipments";

const ShipmentHistory = () => {
  return (
    <>
      <MyShipments />
    </>
  );
};

export default ShipmentHistory;
